<template>
  <div />
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  created() {
    this.setTextSize(100);

    setTimeout(() => {
      // this next tick delay is added for the text size reset to be rendered
      this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'login' });
    }, 0);
  },

  methods: {
    ...mapMutations('member', ['setTextSize']),
  },
};
</script>
